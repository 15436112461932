<template>
	<v-container class="fill-height justify-center">
		<img src="@/assets/logo-small.svg" />
	</v-container>
</template>

<script>
import qs from 'qs'
import { logout } from '@/services/api/axios.js'

export default {
	created() {
		logout()

		function dec2hex(dec) {
			return ('0' + dec.toString(16)).substr(-2)
		}

		function randomCode() {
			var array = new Uint32Array(56 / 2)
			window.crypto.getRandomValues(array)
			return Array.from(array, dec2hex).join('')
		}

		function sha256(plain) {
			// returns promise ArrayBuffer
			const encoder = new TextEncoder()
			const data = encoder.encode(plain)
			return window.crypto.subtle.digest('SHA-256', data)
		}

		function base64urlencode(a) {
			var str = ''
			var bytes = new Uint8Array(a)
			var len = bytes.byteLength
			for (var i = 0; i < len; i++) {
				str += String.fromCharCode(bytes[i])
			}
			return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
		}

		async function challenge_from_verifier(v) {
			const hashed = await sha256(v)
			const base64encoded = base64urlencode(hashed)
			return base64encoded
		}

		const redirect_uri = window.location.origin + '/auth-success'
		const uri = qs.stringify({ redirect_uri })
		const state = Math.random().toString(36).substring(2, 12)

		const code_verifier = randomCode()

		challenge_from_verifier(code_verifier).then((result) => {
			sessionStorage.setItem('code_verifier', code_verifier)
			location.href = `${import.meta.env.VITE_OIDC_AUTH_URL}/authorize?client_id=${
				import.meta.env.VITE_CLIENT_KEY
			}&scope=openid%20profile%20offline_access&${uri}&response_type=code&state=${state}&code_challenge=${result}&code_challenge_method=S256`

			return result
		})
	}
}
</script>
