<template>
	<v-dialog v-model="show" width="360">
		<v-card color="white" title="Error" class="pa-2">
			<v-card-text class="text-secondary" v-if="errorMessage">
				{{ sanitize(errorMessage) }}
			</v-card-text>
			<v-card-text class="text-secondary" v-else>
				An error occurred, please refresh and try again. If the issue continues,
				<router-link @click.stop="show = false" to="/contact-us">contact us.</router-link>
			</v-card-text>

			<v-card-actions class="justify-end">
				<v-btn text="Close" @click.stop="show = false" class="link"></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import sanitizeHtml from 'sanitize-html'
export default {
	props: {
		errorMessage: String,
		value: Boolean
	},
	computed: {
		show: {
			get() {
				return this.value || this.$showError.value
			},
			set(value) {
				this.$Error(false)
				this.$emit('update:modelValue', value)
			}
		}
	},
	methods: {
		sanitize(html) {
			return sanitizeHtml(html)
		}
	}
}
</script>

<style lang="scss" scoped>
:deep(.v-card-title) {
	white-space: wrap;
}
</style>
