export const ArkansasBCBSLight = {
	dark: false,
	colors: {
		background: '#f7fafd',
		surface: '#f7fafd',
		primary: '#2475b7',
		secondary: '#575757',
		'on-background': '#333333'
	}
}
