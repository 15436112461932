import { useSelectedPlan } from '@/stores/plans/selectedPlan.js'

// Retrieves store if it's populated.
// If this the first time accessing the store (store is empty),
// then it makes the API call and updates/returns the store
export async function retrieveSelectedPlan(plan) {
	const store = useSelectedPlan()

	if (!store.loaded) {
		store.updateSelectedPlan(plan)
		store.loaded = true
	}

	return store
}
