import { defineStore } from 'pinia'

export const useUserSettings = defineStore('userSettings', {
	state: () => ({
		displayName: null,
		backToAboutTab: false
	}),
	getters: {
		userSettings() {
			return this.$state
		}
	},
	actions: {
		updateUserSettings(payload) {
			Object.keys(payload).forEach((key) => {
				if (Object.keys(this.$state).includes(key)) {
					this.$state[key] = payload[key]
				}
			})
		},
		updateBackToAboutTab(bool) {
			this.$state.backToAboutTab = bool
		}
	},
	persist: {
		storage: sessionStorage
	}
})
