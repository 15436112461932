import { API } from '../axios'

//Basic function that sets up all Authenticated User API calls.
export const users = async (props) => {
	const { url, method, headers, body } = props
	const path = url !== undefined ? url : ''
	return await API({
		url: `users/${path}`,
		method,
		headers,
		body
	})
}

//Meant for user registration steps 1-4
export const usersRegistration = async (body) => {
	return await API({
		url: 'public/users',
		method: 'POST',
		body
	})
}

//Meant for user forgot user name
export const usersForgotName = async (body) => {
	return await API({
		url: 'public/users/forgotUserName',
		method: 'POST',
		body
	})
}

//Meant for user forgot user password
export const usersForgotPassword = async (body) => {
	return await API({
		url: 'pubic/users/forgotUserPassword',
		method: 'POST',
		body
	})
}

//Meant for changing passwords
export const usersChangePassword = async (body, user) => {
	return await users({
		url: `${user}/password`,
		method: 'PUT',
		body
	})
}

//Get Access Rights for a user with name and code
export const getUsersAccess = async (user, companyId, code, contractType) => {
	return await users({
		url: `${user}/accessrights/${companyId}/${code}/${contractType}`,
		method: 'GET'
	})
}

//Get Memberships of a particular user
export const getUsersMemberships = async (user) => {
	return await users({
		url: `${user}/memberships`,
		method: 'GET'
	})
}

//Delete a user
export const deleteUser = async (user) => {
	return await users({
		url: `${user}`,
		method: 'DELETE'
	})
}
