<template>
	<p>{{ getContent(`LinkingDisclaimer${lobAbbr}`) }}</p>
</template>

<script>
import { useSelectedPlan } from '../stores/plans/selectedPlan'
export default {
	data() {
		return {
			lobAbbr: ''
		}
	},
	mounted() {
		const selectedPlan = useSelectedPlan()
		let tier1 = selectedPlan.selectedPlan.tier1
		if (tier1 === 'HA') {
			this.lobAbbr = 'HA'
		} else if (tier1 === 'BA') {
			this.lobAbbr = 'BAAA'
		} else if (tier1 === 'ABCBS') {
			this.lobAbbr = 'ABCBS'
		} else if (tier1 === 'ABM') {
			this.lobAbbr = 'ABCBS'
		} else if (tier1 === 'OCTAVE') {
			this.lobAbbr = 'OCTAVE'
		}
	}
}
</script>
