<template>
	<footer>
		<div class="footer-body">
			<div class="footer-logo-container">
				<div class="mb-sm-8 mb-4">
					<img src="@/assets/logo.svg" alt="Blueprint Portal" />
				</div>
				<div>
					<p class="text-white">
						Arkansas Blue Cross and Blue Shield, BlueAdvantage Administrators of Arkansas, Health
						Advantage and Octave Blue Cross and Blue Shield are independent licensees of the Blue
						Cross and Blue Shield Association and are licensed to offer health plans in all 75
						counties of Arkansas.
					</p>
				</div>
			</div>

			<div class="store-logos">
				<h4 class="mb-sm-8 mb-4">Get the app</h4>
				<div class="store-logos-container">
					<a href="https://apps.apple.com/sg/app/blueprint-portal/id1074008492" target="_blank">
						<img class="mr-4" src="@/assets/app-store-logo.svg" alt="Apple play store logo link" />
					</a>
					<a
						href="https://play.google.com/store/apps/details?id=com.abcbs.ark_blue_cross&hl=en_US&gl=US"
						target="_blank"
					>
						<img src="@/assets/google-play-logo.svg" alt="Google play store logo link" />
					</a>
				</div>
			</div>
		</div>

		<div class="footer-copyright">
			<p>Copyright © {{ year }}</p>
		</div>
	</footer>
</template>

<script>
export default {
	mounted() {},
	computed: {
		year() {
			const now = new Date()
			return now.getFullYear()
		}
	}
}
</script>

<style lang="scss" scoped>
.text-white {
	color: #fff;
}

footer {
	background-color: $primary-800;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.footer-body {
	display: flex;
	align-items: center;
	gap: 1.5rem;
	justify-content: space-between;
	max-width: 1440px;
	padding-top: 76px;
	padding-bottom: 46px;
	width: 100%;
	padding-left: 112px;
	padding-right: 112px;
}

.footer-copyright {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	gap: 1rem;

	width: 100%;
	max-width: 1440px;
	padding: 0 112px;
	margin-bottom: 36px;
	padding-top: 2rem;

	position: relative;

	* {
		color: $primary-300;
	}

	a {
		&:hover,
		&:focus,
		:active {
			color: $neutral-50;
		}
	}

	.mdi-open-in-new {
		font-size: 1.25rem;
		padding-inline-start: 0.35rem;
	}

	@media only screen and (min-width: 768px) {
		flex-direction: row;
	}
}

.footer-copyright:before {
	content: '';
	height: 1px;
	width: 100%;

	// display: block;

	position: absolute;
	top: -1rem;
	right: 0;
	background-color: #005987;

	margin-bottom: 36px;
}

.footer-logo-container {
	max-width: 600px;

	img {
		max-width: 21rem;
	}
}

.store-logos {
	height: 100%;

	a {
		&:hover,
		&:focus {
			opacity: 0.8;
		}
	}
}

@media only screen and (max-width: 960px) {
	.footer-body {
		padding-left: 32px;
		padding-right: 32px;
	}

	.footer-copyright {
		padding-left: 32px;
		padding-right: 32px;
	}
}

@media only screen and (max-width: 774px) {
	.store-logos-container {
		display: flex;
		flex-direction: column;
		align-items: end;
	}

	.store-logos-container img {
		margin-right: 0 !important;
		margin-bottom: 12px;
	}
}

@media screen and (max-width: $small-glass) {
	.footer-body {
		flex-direction: column;
		padding: 48px 16px 16px;
		margin: 0;
	}

	.footer-logo-container {
		max-width: 100%;
		margin-bottom: 24px;

		img {
			max-width: 100%;
		}
	}

	.store-logos {
		width: 100%;
	}

	.store-logos-container {
		display: block;
	}

	.store-logos-container img {
		margin-right: 16px !important;
		margin-bottom: 0;
	}

	.footer-copyright {
		padding: 0 16px;
	}
}
</style>
