import { defineStore } from 'pinia'

export const useClaimsFilters = defineStore('ClaimsFilters', {
	state: () => ({
		filters: {},

		loaded: false
	}),
	getters: {
		filters() {
			return this.$state.filters
		},
		loaded() {
			return this.$state.loaded
		}
	},
	actions: {
		updateClaimsFilters(payload) {
			this.$state.filters = payload
			this.$state.loaded = true
		},
		resetClaimsFilters() {
			this.$state.filters = {}
			this.$state.loaded = false
		}
	},
	persist: {
		storage: sessionStorage
	}
})
