<template>
	<ConfirmModal
		v-model="showModal"
		confirmTitle="Your session is about to end."
		:confirmMessage="getContent('site-timeout-message')"
		confirmActionText="Sign out"
		cancelActionText="Stay signed in"
		:confirmAction="logoutCta"
		:cancelAction="refreshTimeout"
	/>
</template>

<script>
import ConfirmModal from './ConfirmModal.vue'
import { logout } from '@/services/api/axios.js'
const timeoutDuration =
	(import.meta.env.MODE == 'development' && import.meta.env.DEV ? 99 : 12) * 60 * 1000
const autoLogoutDuration = 3 * 60 * 1000

export default {
	name: 'TimeoutModal',
	components: { ConfirmModal },
	created() {
		window.addEventListener('user-activity', this.refreshTimeout)
	},
	data() {
		return {
			showModal: false,
			warningTimeout: null,
			logoutTimeout: null
		}
	},
	beforeDestroy() {
		window.removeEventListener('user-activity', this.refreshTimeout)
	},
	methods: {
		refreshTimeout() {
			this.setSessionTimeout()
		},
		setSessionTimeout() {
			clearTimeout(this.warningTimeout)
			clearTimeout(this.logoutTimeout)
			if (sessionStorage.getItem('access_token') != null) {
				this.warningTimeout = setTimeout(() => {
					this.showModal = true
					this.logoutTimeout = setTimeout(() => {
						this.logoutCta()
					}, autoLogoutDuration)
				}, timeoutDuration)
			}
		},
		logoutCta() {
			this.showModal = false
			logout()
			this.$router.push('/login')
		}
	}
}
</script>
