import { defineStore } from 'pinia'

export const useAccessRights = defineStore('accessRights', {
	state: () => ({
		accessRights: null,

		loaded: false
	}),
	getters: {
		accessRights() {
			return this.$state.accessRights
		},
		loaded() {
			return this.$state.loaded
		}
	},
	actions: {
		updateAccessRights(payload) {
			this.$state.accessRights = payload
			this.$state.loaded = true
		}
	},
	persist: {
		storage: sessionStorage
	}
})
