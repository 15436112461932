<template>
	<footer>
		<div class="footer-body">
			<div class="footer-logo-container">
				<div class="mb-sm-8 mb-4">
					<img v-if="logoUrl" :src="logoUrl" :alt="logoAltText" />
				</div>
				<div>
					<p class="text-white">
						{{ licenseText }} is an Independent Licensee of the Blue Cross and Blue Shield
						Association and is licensed to offer health plans in all 75 counties of Arkansas.
					</p>
				</div>
			</div>

			<div class="store-logos">
				<h4 class="mb-sm-8 mb-4">Get the app</h4>
				<div class="store-logos-container">
					<a href="https://apps.apple.com/sg/app/blueprint-portal/id1074008492" target="_blank">
						<img class="mr-4" src="@/assets/app-store-logo.svg" alt="Apple play store logo link" />
					</a>
					<a
						href="https://play.google.com/store/apps/details?id=com.abcbs.ark_blue_cross&hl=en_US&gl=US"
						target="_blank"
					>
						<img src="@/assets/google-play-logo.svg" alt="Google play store logo link" />
					</a>
				</div>
			</div>
		</div>

		<div class="footer-copyright">
			<p>Copyright © {{ year }} {{ copyrightText }}</p>
			<a @click="showDisclaimer = !showDisclaimer">
				Linking Disclaimer <v-icon icon="mdi:mdi-open-in-new" />
			</a>
		</div>
	</footer>
	<LinkingDisclaimer v-model="showDisclaimer" @closeDialog="showDisclaimer = false" />
</template>

<script>
import { useSelectedPlan } from '../stores/plans/selectedPlan'
import LinkingDisclaimer from './LinkindDisclaimerModal.vue'

export default {
	components: {
		LinkingDisclaimer
	},
	data() {
		return {
			licenseText: 'Arkansas Blue Cross and Blue Shield ',
			logoPath: null,
			copyrightText: 'Arkansas Blue Cross and Blue Shield',
			logoAltText: 'Arkansas Blue Cross and Blue Shield',
			showDisclaimer: false,
			lobAbbr: '',
			lob: ''
		}
	},
	mounted() {
		this.getFooterContent()
	},
	methods: {
		getFooterContent() {
			const selectedPlan = useSelectedPlan()
			let tier1 = selectedPlan.selectedPlan.tier1

			if (tier1 === 'HA') {
				this.licenseText = 'Health Advantage '
				this.copyrightText = 'HMO Partners, Inc.'
				this.logoAltText = this.licenseText
				this.logoPath = 'ha-logo.svg'
				this.lobAbbr = 'HA'
				this.lob = this.licenseText
			} else if (tier1 === 'BA') {
				this.licenseText = 'BlueAdvantage Administrators of Arkansas '
				this.copyrightText = 'BlueAdvantage Administrators of Arkansas'
				this.logoAltText = this.licenseText
				this.logoPath = 'ba-logo.svg'
				this.lobAbbr = 'BAAA'
				this.lob = this.licenseText
			} else if (tier1 === 'ABCBS') {
				this.licenseText = 'Arkansas Blue Cross and Blue Shield '
				this.copyrightText = 'Arkansas Blue Cross and Blue Shield'
				this.logoAltText = this.licenseText
				this.logoPath = 'abcbs-logo.svg'
				this.lobAbbr = 'ABCBS'
				this.lob = this.licenseText
			} else if (tier1 === 'ABM') {
				this.licenseText = 'Arkansas Blue Medicare '
				this.copyrightText = 'Arkansas Blue Cross and Blue Shield'
				this.logoAltText = this.licenseText
				this.logoPath = 'abm-logo.svg'
				this.lobAbbr = 'ABCBS'
				this.lob = this.copyrightText
			} else if (tier1 === 'OCTAVE') {
				this.licenseText =
					'This website is owned and operated by USAble HMO Inc., d/b/a Octave. Octave '
				this.copyrightText = 'Octave'
				this.logoAltText = this.copyrightText
				this.logoPath = 'octave-logo.svg'
				this.lob = this.copyrightText
				this.lobAbbr = this.copyrightText
			}
		}
	},
	computed: {
		year() {
			const now = new Date()
			return now.getFullYear()
		},
		logoUrl() {
			if (!this.logoPath) return false
			const imageUrl = new URL(`../assets/${this.logoPath}`, import.meta.url).href
			return imageUrl ? imageUrl : false
		}
	}
}
</script>

<style lang="scss" scoped>
.text-white {
	color: #fff;
}

footer {
	background-color: $primary-800;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.footer-body {
	display: flex;
	align-items: center;
	gap: 1.5rem;
	justify-content: space-between;
	max-width: 1440px;
	padding-top: 76px;
	padding-bottom: 46px;
	width: 100%;
	padding-left: 112px;
	padding-right: 112px;
}

.footer-copyright {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	gap: 1rem;

	width: 100%;
	max-width: 1440px;
	padding: 0 112px;
	margin-bottom: 36px;
	padding-top: 2rem;

	position: relative;

	* {
		color: $primary-300;
	}

	a {
		&:hover,
		&:focus,
		:active {
			color: $neutral-50;
		}
	}

	.mdi-open-in-new {
		font-size: 1.25rem;
		padding-inline-start: 0.35rem;
	}

	@media only screen and (min-width: 768px) {
		flex-direction: row;
	}
}

.footer-copyright:before {
	content: '';
	height: 1px;
	width: 100%;

	// display: block;

	position: absolute;
	top: -1rem;
	right: 0;
	background-color: #005987;

	margin-bottom: 36px;
}

.footer-logo-container {
	// max-width: 430px;
}

.store-logos {
	height: 100%;

	a {
		&:hover,
		&:focus {
			opacity: 0.8;
		}
	}
}

@media only screen and (max-width: 960px) {
	.footer-body {
		padding-left: 32px;
		padding-right: 32px;
	}

	.footer-copyright {
		padding-left: 32px;
		padding-right: 32px;
	}
}

@media only screen and (max-width: 774px) {
	.store-logos-container {
		display: flex;
		flex-direction: column;
		align-items: end;
	}

	.store-logos-container img {
		margin-right: 0 !important;
		margin-bottom: 12px;
	}
}

@media screen and (max-width: $small-glass) {
	.footer-body {
		flex-direction: column;
		padding: 48px 16px 16px;
		margin: 0;
	}

	.footer-logo-container {
		max-width: 100%;
		margin-bottom: 24px;

		img {
			max-width: 100%;
		}
	}

	.store-logos {
		width: 100%;
	}

	.store-logos-container {
		display: block;
	}

	.store-logos-container img {
		margin-right: 16px !important;
		margin-bottom: 0;
	}

	.footer-copyright {
		padding: 0 16px;
	}
}
</style>
