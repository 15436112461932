import { getUsersAccess } from '@/services/api/users/users.js'
import { useAccessRights } from '@/stores/accessRights/accessRights.js'
import { retrieveSelectedPlan } from '@/composables/selectedPlans.comp.js'

// Retrieves store if it's populated.
// If this the first time accessing the store (store is empty),
// then it makes the API call and updates/returns the store
export async function retrieveAccessRights() {
	const store = useAccessRights()
	const planStore = await retrieveSelectedPlan()

	if (!store.loaded) {
		await getUsersAccess(
			planStore.username,
			planStore.companyId,
			planStore.memberId,
			planStore.contractType
		)
			.then((res) => {
				store.updateAccessRights(res)
			})
			.catch((err) => {
				console.error(err)
			})
	}

	return store
}
