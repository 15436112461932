import { defineStore } from 'pinia'

export const usePatientData = defineStore('patientData', {
	state: () => ({
		patientData: {},

		loaded: false
	}),
	getters: {
		patientData() {
			return this.$state.patientData
		},
		loaded() {
			return this.$state.loaded
		}
	},
	actions: {
		updatePatientData(memberId, payload) {
			this.$state.patientData[memberId] = payload
			this.$state.loaded = true
		},
		resetPatientData() {
			this.$state.patientData = {}
			this.$state.loaded = false
		}
	},
	persist: {
		storage: sessionStorage
	}
})
