<template>
	<v-container>
		<div class="navbar" :class="{ 'justify-end': !planSelected }">
			<button v-if="planSelected" class="mr-10" @click="goToDashboard">
				<img alt="home-logo" class="logo" src="@/assets/logo-small.svg" />
			</button>
			<div
				class="links-window"
				@click.self="showDrawer = false"
				v-if="windowWidth > 1279 || showDrawer"
			>
				<div class="links-container">
					<div
						class="d-lg-none d-flex py-4 px-8"
						:class="[planSelected === true ? 'justify-space-between' : 'justify-end']"
					>
						<button v-if="planSelected" @click="goToDashboard">
							<img alt="home-logo" class="logo" src="@/assets/logo-small.svg" />
						</button>
						<button class="close-menu" @click="showDrawer = false">
							<v-icon icon="mdi-close" />
						</button>
					</div>
					<div class="links" v-if="planSelected">
						<v-list-item @click="goToDashboard"> Home </v-list-item>
						<div class="dropdown-link" v-if="careLinks.filter((x) => x.enabled).length > 0">
							<v-list-item @click="toggleCareDropdown" @focus="dropdownFocus">
								Care
								<v-icon :icon="careDropdown ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
							</v-list-item>
							<div v-show="careDropdown" @mouseleave="careDropdown = false" class="dropdown">
								<v-list-item
									v-for="link in careLinks.filter((x) => x.enabled)"
									@click="routeTo(link.link)"
								>
									<v-icon :icon="'mdi-' + link.icon" />
									{{ link.title }}
								</v-list-item>
							</div>
						</div>
						<v-list-item
							to="/payment-center"
							@click="closeMenus"
							v-if="
								accessRights?.payBill ||
								(!accessRights?.payBill &&
									accessRights?.pageIdentifiers.some((x) => x.type == 'medicarePayment'))
							"
							:active="false"
						>
							Pay Bill
						</v-list-item>
						<v-list-item
							to="/id-card"
							@click="closeMenus"
							v-if="accessRights?.idCards"
							:active="false"
						>
							ID Card
						</v-list-item>
						<v-list-item
							:to="programsRoute()"
							@click="closeMenus"
							v-if="accessRights?.programs.length"
							:active="false"
						>
							Programs
						</v-list-item>
						<div v-show="policyLinks.filter((x) => x.enabled).length > 0" class="dropdown-link">
							<v-list-item @click="togglePolicyDropdown" @focus="dropdownFocus">
								Policy
								<v-icon :icon="policyDropdown ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
							</v-list-item>
							<div
								v-show="policyDropdown"
								@mouseleave="policyDropdown = false"
								class="dropdown policy"
							>
								<v-list-item
									v-for="link in policyLinks.filter((x) => x.enabled)"
									@click="routeTo(link.link)"
								>
									<v-icon :icon="'mdi-' + link.icon" />
									{{ link.title }}
								</v-list-item>
							</div>
						</div>
						<v-list-item
							to="/pharmacy"
							@click="closeMenus"
							v-if="accessRights?.pharmacyCenter || accessRights?.rxSavingsSolutions"
							:active="false"
						>
							Pharmacy
						</v-list-item>
						<v-list-item
							to="/documents"
							@click="closeMenus"
							v-if="accessRights?.forms"
							:active="false"
						>
							Documents
						</v-list-item>
						<v-list-item to="/contact-us" @click="closeMenus" :active="false">
							Contact Us
						</v-list-item>
					</div>
					<div v-else></div>

					<div class="profile">
						<v-btn
							icon="mdi-account-outline"
							class="profile-btn"
							@click="showProfile = !showProfile"
							variant="text"
							aria-label="Profile Button"
						/>
						<div
							class="profile-panel"
							v-show="showProfile || showDrawer"
							@mouseleave="showProfile = false"
						>
							<div class="heading">
								<div>
									<p>{{ greeting }},</p>
									<p class="font-weight-medium">{{ displayName }}</p>
								</div>
							</div>
							<div class="body" v-if="planSelected">
								<h4>{{ planName }}</h4>
								<div class="row">
									<p class="secondary">Member ID:</p>
									<p class="font-weight-medium">{{ memberId }}</p>
								</div>
								<div class="row">
									<p class="secondary">Start Date:</p>
									<p class="font-weight-medium">{{ startDate }}</p>
								</div>
								<v-btn variant="text" class="link mt-1" @click="closeMenus" to="/select-plan"
									>Switch Plans</v-btn
								>
							</div>
							<v-list-item
								class="settings fullsize"
								width="100%"
								height="44"
								v-if="planSelected"
								:active="false"
								to="/settings"
								@click="closeMenus"
							>
								<v-icon icon="mdi-cog-outline" class="me-2" />
								Settings
							</v-list-item>
							<v-list-item
								class="fullsize"
								width="100%"
								height="44"
								@click="logoutCTA"
								@focusout="showProfile = false"
							>
								<v-icon icon="mdi-logout" class="me-2" />
								Sign Out
							</v-list-item>
						</div>
						<v-btn
							class="d-lg-none secondary mt-5"
							width="100%"
							height="44"
							variant="outlined"
							v-if="planSelected"
							to="/settings"
							:active="false"
							@click="closeMenus"
						>
							<v-icon icon="mdi-cog-outline" class="me-2" />
							Settings
						</v-btn>
						<v-btn
							class="d-lg-none secondary"
							width="100%"
							height="44"
							variant="outlined"
							@click="logoutCTA"
						>
							Sign Out
							<v-icon icon="mdi-logout" class="ms-2" />
						</v-btn>
					</div>
				</div>
			</div>
			<button class="d-lg-none" @click="maintainFocus" aria-label="Mobile Navigation Menu">
				<v-icon icon="mdi-menu" class="menu" />
			</button>
		</div>
	</v-container>
</template>

<script>
import { logout } from '@/services/api/axios.js'
import { ssoBridge } from '@/services/sso/ssoBridge.js'
import { useAccessRights } from '@/stores/accessRights/accessRights.js'
import { useSelectedPlan } from '@/stores/plans/selectedPlan.js'
import { useUserSettings } from '@/stores/settings/userSettings.js'
import { MMDDYYYY } from '@/utils/utils.js'
import { storeToRefs } from 'pinia'

export default {
	data() {
		return {
			careDropdown: false,
			policyDropdown: false,
			showProfile: false,
			showDrawer: false,
			planSelected: false,
			displayName: null,
			windowWidth: 1900,
			careLinks: [
				{
					icon: 'text-box-outline',
					title: 'Claims',
					link: '/medical-claims'
				},
				{
					icon: 'chart-pie-outline',
					title: 'Spending Details',
					link: '/spending-details'
				},
				{
					icon: 'card-account-details-outline',
					title: 'Personal Health Record',
					link: '/health-records'
				},
				{
					icon: 'swap-horizontal',
					title: 'Health Reimbursement Account',
					link: '/health-reimbursement'
				},
				{
					icon: 'magnify',
					title: 'Find Care & Costs',
					link: '/find-care'
				}
			],
			policyLinks: [
				{
					icon: 'shield-check-outline',
					title: 'Coverage & Copays',
					link: '/coverage-and-copays'
				},
				{
					icon: 'stethoscope',
					title: 'Primary Care Provider',
					link: '/primary-care-provider'
				},
				{
					icon: 'file-document-arrow-right-outline',
					title: 'Prior Authorizations',
					link: '/prior-authorizations'
				},
				{
					icon: 'text-box-check-outline',
					title: 'Medical Review Guidelines',
					link: { app: 'interqual' }
				},
				{
					icon: 'folder-edit-outline',
					title: 'Update Policy Info',
					link: '/' // saturated on mount
				},
				{
					icon: 'open-in-new',
					title: 'Your Plan',
					link: '/' // saturated on mount
				},
				{
					icon: 'transit-connection-variant',
					title: 'Coordination of Benefits',
					link: { app: 'cob' }
				},
				{
					icon: 'airplane',
					title: 'Coverage when Traveling Abroad',
					link: '/traveling-abroad'
				}
			],
			memberId: '',
			startDate: '',
			planName: ''
		}
	},
	setup() {
		const store = useAccessRights()
		const { accessRights, loaded } = storeToRefs(store)
		const { displayName } = storeToRefs(useUserSettings())
		return { accessRights, loaded, displayName }
	},
	mounted() {
		this.windowWidth = window.innerWidth
		window.addEventListener('resize', () => {
			this.windowWidth = window.innerWidth
		})
		if (this.loaded) {
			this.getUserAccess()
		}
	},
	methods: {
		getUserAccess() {
			this.planSelected = true
			const selectedPlan = useSelectedPlan()
			this.memberId = selectedPlan.memberId
			this.startDate = MMDDYYYY(selectedPlan.origEffDate)
			this.planName = selectedPlan.lobDisplayName

			// Care Links dropdown
			this.careLinks.find((x) => x.title == 'Claims').enabled = this.accessRights.claims
			this.careLinks.find((x) => x.title == 'Spending Details').enabled =
				this.accessRights.deductible
			this.careLinks.find((x) => x.title == 'Personal Health Record').enabled =
				this.accessRights.personalHealthRecord
			this.careLinks.find((x) => x.title == 'Health Reimbursement Account').enabled =
				this.accessRights.healthReimbursementAccount
			this.careLinks.find((x) => x.link == '/find-care').enabled =
				this.accessRights.findCareAndCostOptions.length > 0
			// Policy Links dropdown
			this.policyLinks.find((x) => x.title == 'Coverage & Copays').enabled =
				this.accessRights.whosCovered
			this.policyLinks.find((x) => x.title == 'Primary Care Provider').enabled =
				this.accessRights.choosePrimaryCareProvider
			this.policyLinks.find((x) => x.title == 'Prior Authorizations').enabled =
				this.accessRights.inpatientMedicalAdmissionRequest
			this.policyLinks.find((x) => x.title == 'Medical Review Guidelines').enabled =
				this.accessRights.pageIdentifiers.some((x) => x.type == 'interqual')
			this.policyLinks.find((x) => x.title == 'Update Policy Info').enabled =
				this.accessRights.updatePolicy || this.accessRights.updatePolicyMetallic
			this.policyLinks.find((x) => x.title == 'Your Plan').enabled =
				this.accessRights.maPlanBenefits
			this.policyLinks.find((x) => x.title == 'Coordination of Benefits').enabled =
				this.accessRights.updateOtherCoverageInformation
			this.policyLinks.find((x) => x.title == 'Coverage when Traveling Abroad').enabled =
				this.accessRights.coverageWhenTravelingAbroad

			// additional link modifications
			this.careLinks.find((x) => x.link == '/find-care').title =
				this.accessRights.findCareAndCostDisplayText
			this.policyLinks.find((x) => x.title == 'Your Plan').link =
				this.accessRights.maPlanBenefitsLink
			if (this.accessRights.updatePolicy) {
				this.policyLinks.find((x) => x.title == 'Update Policy Info').link = {
					app: 'updatepolicy'
				}
			} else if (this.accessRights.updatePolicyMetallic) {
				this.policyLinks.find((x) => x.title == 'Update Policy Info').link = {
					app: 'updatepolicymetallic'
				}
			}
		},
		programsRoute() {
			if (this.accessRights.pageIdentifiers.some((x) => x.identifier == 'abb_benefits')) {
				return '/abb-programs'
			} else {
				return '/programs'
			}
		},
		toggleCareDropdown() {
			this.policyDropdown = false
			this.careDropdown = !this.careDropdown
		},
		togglePolicyDropdown() {
			this.careDropdown = false
			this.policyDropdown = !this.policyDropdown
		},
		routeTo(location) {
			if (typeof location == 'string') {
				if (location.includes('http')) {
					window.open(location, '_blank')
				} else {
					this.$router.push(location)
				}
				this.closeMenus()
			} else {
				const selectedPlan = useSelectedPlan()
				const body = JSON.stringify({
					app: location.app,
					userName: selectedPlan.username,
					companyId: selectedPlan.companyId,
					memberId: selectedPlan.memberId,
					contractType: selectedPlan.contractType,
					relayState: ''
				})

				ssoBridge(body)
			}
		},
		closeMenus() {
			this.careDropdown = false
			this.policyDropdown = false
			this.showDrawer = false
			this.showProfile = false
		},
		goToDashboard() {
			this.careDropdown = false
			this.policyDropdown = false
			this.showDrawer = false
			this.showProfile = false
			const selectedPlan = useSelectedPlan()
			if (selectedPlan.contractType == 'Medical') {
				this.$router.push({ name: 'MemberDashboard' })
			} else if (selectedPlan.contractType == 'Dental') {
				this.$router.push({ path: '/dental/dashboard' })
			} else if (selectedPlan.contractType == 'Vision') {
				this.$router.push({ path: '/vision/dashboard' })
			} else {
				return
			}
		},
		logoutCTA() {
			if (document.querySelector('body').classList.contains('chat_started')) {
				Five9ChatTerminate()
			}
			logout()
			this.$router.push('/login')
		},
		maintainFocus() {
			this.showDrawer = !this.showDrawer
			//Setting a timeout so this wont run until the menu is actually open
			setTimeout(() => {
				// Variables
				const menuItems = document.querySelectorAll('.links-container > *')
				const firstItem = menuItems[0]
				const lastItem = menuItems[menuItems.length - 1].lastChild

				if (firstItem.firstChild.nodeType === 1) {
					// When the menu is open setting the focus to the first elements first child
					firstItem.firstChild.focus()

					//Watching for any shift+Tab to keep the user in the menu and not to go outside of it
					firstItem.firstChild.addEventListener('keydown', function (e) {
						if (e.shiftKey) {
							e.preventDefault()
						}
					})
				}

				if (lastItem) {
					// If the user hits the end of the menu going back to the top rather than continuing through the page
					lastItem.addEventListener('focus', function () {
						lastItem.addEventListener('keydown', function (e) {
							if (e.key === 'Tab') {
								firstItem.firstChild.focus()
								e.preventDefault()
							}
						})
					})
				}
			}, 100)
		},
		dropdownFocus(e) {
			// Grabbing the element being focused
			const targetItem = e.target
			if (targetItem.nextElementSibling) {
				const dropdownItems = targetItem.nextElementSibling.querySelectorAll('.dropdown > *')
				var self = this
				// Looping through each of the dropdown items and watching for a keydown/tab key being pressed
				dropdownItems.forEach((element) => {
					element.addEventListener('focus', function () {
						// Checking for the element to have a sibling next
						if (!element.nextElementSibling) {
							element.addEventListener('keydown', function (x) {
								// If the tab key is pressed adding the display none (prevents the dropdown from staying open if you tab to next item)
								if (x.key === 'Tab') {
									// Mimicking how its behaving currently
									element.parentElement.style = 'display: none;'
									self.careDropdown = false
									self.policyDropdown = false
								}
							})
						}
					})
				})
			}
		}
	},
	computed: {
		greeting() {
			const today = new Date()
			return today.getHours() < 12
				? 'Good Morning'
				: today.getHours() < 17
					? 'Good Afternoon'
					: 'Good Evening'
		}
	},
	watch: {
		loaded(newValue, oldValue) {
			if (newValue) {
				this.getUserAccess()
			} else {
				this.planSelected = false
			}
		},
		displayName(newValue, oldValue) {
			if (newValue) this.displayName = newValue
		}
	}
}
</script>

<style lang="scss" scoped>
.v-container {
	background: white;
	max-width: 100%;
	padding: 0 112px;
	margin-bottom: 0;
	border-bottom: 1px solid $neutral-200;
}
.navbar {
	width: 100%;
	height: 80px;
	max-width: 1280px;
	margin: 0 auto;
	padding: 20px 0;
	display: flex;
	align-items: center;
	font-weight: 500;
	color: $neutral-600;
	flex-shrink: 1;
}
a.v-list-item,
a.v-btn,
.v-list-item {
	color: $neutral-600;

	&:hover,
	&:focus {
		color: $neutral-900;

		:deep(.v-list-item__overlay) {
			--v-hover-opacity: 0.1;
		}
	}
}
.links-window {
	width: 100%;
}
.links-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.logo {
	height: 32px;
	width: 32px;
	cursor: pointer;
}

.close-menu {
	min-height: 32px;
}

.menu {
	cursor: pointer;
	padding: 0 16px;
}
.profile {
	position: relative;
}
.profile-btn {
	width: 40px !important;
	height: 40px !important;
	background: $neutral-200;
	border: 1px solid $neutral-300;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.links {
	display: flex;
	gap: 4px;
	align-items: center;
}
.dropdown-link {
	position: relative;
}
.dropdown {
	position: absolute;
	top: 32px;
	left: -32px;
	background: white;
	color: $neutral-900;
	padding: 12px 0;
	border: 1px solid $neutral-200;
	border-radius: $radius-md;
	display: flex;
	flex-direction: column;
	width: 320px;
	box-shadow: $shadow-sm;
	z-index: 1;

	.v-list-item {
		padding: 16px 16px;
	}
	.v-icon {
		margin-right: 12px;
		color: $text-brand;
	}
}
.profile-panel {
	position: absolute;
	top: 44px;
	right: 0;
	display: flex;
	flex-direction: column;
	width: 208px;
	font-size: 14px;
	background: white;
	box-shadow: $shadow-sm;
	border: 1px solid $neutral-200;
	border-radius: $radius-sm;
	z-index: 1;

	.heading {
		display: flex;
		padding: 12px 16px;
		gap: 12px;
		border-bottom: 1px solid $neutral-200;
	}
	.body {
		padding: 12px 16px;
		background: $primary-50;
	}
	h4 {
		margin-bottom: 4px;
		font-size: 14px;
	}
	p {
		font-weight: 400;
	}
	.row {
		display: flex;
		gap: 4px;
	}
	.v-btn {
		justify-content: flex-start;
		border: none;
		font-size: 14px;
	}
}

@media only screen and (max-width: $large-glass) {
	.navbar {
		justify-content: space-between;
		height: 72px;
		background: white;
	}
	.links-window {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background: rgba($color: #000000, $alpha: 0.5);
		height: 100%;
	}
	.links-container {
		position: fixed;
		right: 0;
		display: block;
		background: white;
		height: 100%;
		width: 600px;
		max-width: 100%;
		overflow-y: auto;
		animation-name: fade-in;
		animation-duration: 0.15s;
		animation-timing-function: ease-out;

		.profile {
			margin: 24px 16px;

			.profile-btn {
				display: none;
			}
		}
		.fullsize {
			display: none;
			width: 100%;
		}
		.v-btn {
			margin-top: 16px;
		}
	}
	@keyframes fade-in {
		0% {
			transform: translateX(6%);
		}
		100% {
			transform: translateX(0%);
		}
	}
	.links {
		flex-direction: column;
		gap: 4px;
		padding-top: 24px;
		align-items: flex-start;
	}
	.dropdown {
		top: unset;
		left: 12px;
		background: $primary-50;
		gap: 0;
		margin-top: 20px;
	}
	.profile-panel {
		position: relative;
		top: unset;
		width: 100%;
		box-shadow: none;
		font-size: 16px;
		border: 1px solid $primary-200;
		z-index: 0;

		h4 {
			font-size: 20px;
		}
		.body {
			padding: 16px 24px;
			border-radius: $radius-sm;
		}
		p {
			width: 120px;
		}
		.v-btn {
			font-size: 16px;
		}
	}
}

@media only screen and (max-width: 960px) {
	.v-container {
		padding: 0 32px;
	}
}

@media only screen and (max-width: $small-glass) {
	.v-container {
		padding: 0 16px;
	}
}
</style>
