import { searchPatient } from '@/services/api/fhirAPI/patient/patient.js'
import { usePatientData } from '@/stores/patientData/patientData.js'
import { useSelectedPlan } from '@/stores/plans/selectedPlan.js'
import { retrieveCoverage } from '@/composables/coverage.comp.js'

// Retrieves store if it's populated.
// If this the first time accessing the store (store is empty),
// then it makes the API call and updates/returns the store
export async function retrievePatientData() {
	const store = usePatientData()
	const planStore = useSelectedPlan()

	if (!store.loaded) {
		// check if dependent or policyholder
		if (planStore.isPolicyHolder) {
			await retrieveCoverage()
			await searchPatient(planStore.contractId)
				.then((res) => {
					res.loaded = true
					res.entry.forEach((member) => {
						store.updatePatientData(member.resource.id, member.resource)
					})
				})
				.catch((err) => {
					console.error(err)
				})
		} else {
			// if not policy holder only grab by memberId
			await searchPatient(planStore.memberId)
				.then((res) => {
					res.loaded = true
					res.entry.forEach((member) => {
						store.updatePatientData(member.resource.id, member.resource)
					})
				})
				.catch((err) => {
					console.error(err)
				})
		}
	}

	return store
}
