<template>
	<v-overlay v-model="showLoadingTakeover" class="d-flex align-center justify-center" color="white">
		<v-progress-circular indeterminate size="48" color="primary"> </v-progress-circular>
	</v-overlay>
</template>

<script>
export default {
	name: 'LoadingSpinnerTakeover',
	computed: {
		showLoadingTakeover() {
			return this.$showLoadingTakeover.value
		}
	}
}
</script>

<style lang="scss" scoped>
.v-overlay {
	// This overrides the z-index of Five9SocialWidget
	z-index: 9001 !important;
}
</style>
