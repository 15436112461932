import { API } from '../api/axios'

export const ssoBridge = (body) => {
	API({
		url: 'sso',
		method: 'POST',
		body
	}).then((res) => {
		const parser = new DOMParser()
		const doc = parser.parseFromString(res.ssoForm, 'text/html')

		// setup script element
		// ios safari requires a timeout to open a new window
		setTimeout(() => {
			var win = window.open('')
			const scriptContent = doc.querySelector('script').textContent
			const ssoScript = win.document.createElement('script')
			ssoScript.id = 'ssoScript'
			ssoScript.textContent = scriptContent

			// consume form and script
			const bodyContent = doc.querySelector('form')
			doc.getElementById('ssoForm')
			win.document.body.appendChild(bodyContent)
			win.document.head.appendChild(ssoScript)
			win.document.body.removeChild(bodyContent)
			win.document.head.removeChild(ssoScript)
		})
	})
}
